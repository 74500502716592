// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-community-jsx": () => import("/home/runner/work/microfleet.io/microfleet.io/src/pages/community.jsx" /* webpackChunkName: "component---src-pages-community-jsx" */),
  "component---src-pages-contact-jsx": () => import("/home/runner/work/microfleet.io/microfleet.io/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-docs-jsx": () => import("/home/runner/work/microfleet.io/microfleet.io/src/pages/docs.jsx" /* webpackChunkName: "component---src-pages-docs-jsx" */),
  "component---src-pages-expertise-jsx": () => import("/home/runner/work/microfleet.io/microfleet.io/src/pages/expertise.jsx" /* webpackChunkName: "component---src-pages-expertise-jsx" */),
  "component---src-pages-guides-jsx": () => import("/home/runner/work/microfleet.io/microfleet.io/src/pages/guides.jsx" /* webpackChunkName: "component---src-pages-guides-jsx" */),
  "component---src-pages-index-jsx": () => import("/home/runner/work/microfleet.io/microfleet.io/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

